// TODO(aes): some of the event handlers here are context specific,
// move them over to the relevant pages/components
export function init() {
        if (window.__ives_call_out_custom) return
        window.__ives_call_out_custom = true

        $(document).ready(function() {
                AOS.init({
                        duration: 1200
                });

                $('body').on('click', '.print-invoice', function(e) {
                        $("#modal-billing-invoice").printThis();
                });

                $("body").on("click", '.btn-accept-terms-and-condition', function(e) {
                        $('#user_terms_of_service').prop('checked', true);
                });

                //Menu Toggle Script

                $("body").on("click", "#menu-toggle", function(e) {
                        e.preventDefault();
                        $("#wrapper").toggleClass("toggled");
                });

                //Show password

                $("body").on("click", "#show-password", function() {
                        var x = document.getElementById("user_password");
                        if (x.type === "password") {
                                x.type = "text";
                                $(this).find("svg").css("color", "gray");
                        } else {
                                x.type = "password";
                                $(this).find("svg").css("color", "#dde2e8");
                        }
                });
                $("body").on("click", "#show-confirm-password", function() {
                        var x = document.getElementById("confirm-password");
                        if (x.type === "password") {
                                x.type = "text";
                                $(this).find("svg").css("color", "gray");
                        } else {
                                x.type = "password";
                                $(this).find("svg").css("color", "#dde2e8");
                        }
                });

                $("#form-signup").find("#password").on("keypress", function() {
                        confirmPasswordInput.val("");
                });
                $("#form-signup").find("#confirm-password").on("keyup", validateConfirmPassword);

                $('#form-signup').submit(function(e) {
                        if (!validateConfirmPassword()) {
                                e.preventDefault();
                        }
                });

                $('.form .btn-clear-form').click(function(e) {
                        $(this).parents(".form").trigger('reset');
                });

                $("body").on("click", '#notification-toggle', function(e) {
                        $("#notification-popup").toggle();
                });

                $("body").on("click", '.ivr-filter', function(e) {
                        $(".filter-list").toggle();
                });
                $(".signup-otp .otp-input, #modal-enter-verification .verification-pin").keydown(function() {
                        $(this).val("");
                });
                $(".signup-otp .otp-input, #modal-enter-verification .verification-pin").keyup(function() {

                        if (this.value.length == this.maxLength) {
                                var $next = $(this).next('.otp-input, #modal-enter-verification .verification-pin ');
                                if ($next.length) {
                                        $(this).next('.otp-input, #modal-enter-verification .verification-pin').focus();
                                } else $(this).blur();
                        }
                });

                $("body").on("keyup", "#modal-enter-verification .verification-pin", function() {
                        var isValid = true;
                        $("#modal-enter-verification .verification-pin").each(function() {
                                var element = $(this);
                                if (element.val() == "") {
                                        isValid = false;
                                        $("#modal-enter-verification .buttons .blue-btn").addClass("btn-disabled");
                                }
                        });
                        if (isValid) {
                                $("#modal-enter-verification .buttons .blue-btn").removeClass("btn-disabled");
                        }
                });

                $(".submit-documents .upload-input:file").change(function() {
                        $(".submit-documents .upload-input").each(function() {
                                if ($(this).get(0).files.length === 0) {
                                        $(".submit-documents .send-files").addClass("btn-disabled");
                                } else {
                                        $(".submit-documents .send-files").removeClass("btn-disabled");
                                        return false;
                                }
                        });
                });

                $('body').on('click', '#caleandar .hover-calendar-item-details .hover-date img.back-btn', function() {
                        $('.hover-calendar-item-details-wrap').addClass("hide");
                        $('.hover-calendar').removeClass("hide");
                });

                $("#modal-request-callerid input").bind('keyup change', function() {
                        var r1 = $("#modal-request-callerid #callerid-input-mobilenum");
                        var r2 = $("#modal-request-callerid #callerid-input-file");

                        if ((r1.val().length == 10) && (r2.val())) {
                                $("#modal-request-callerid .buttons .blue-btn").removeClass("btn-disabled");
                        } else {
                                $("#modal-request-callerid .buttons .blue-btn").addClass("btn-disabled");
                        }
                });

                $("body").on("click", ".profile .nav-general", function() {
                        $(this).addClass("active");
                        $(".profile .nav-security").removeClass("active");
                        $(".profile .nav-plan").removeClass("active");

                        $(".profile .profile-general-info").removeClass("hide");
                        $(".profile .profile-security").addClass("hide");
                        $(".profile .profile-plan").addClass("hide");
                });
                $("body").on("click", ".profile .nav-security", function() {
                        $(this).addClass("active");
                        $(".profile .nav-general").removeClass("active");
                        $(".profile .nav-plan").removeClass("active");

                        $(".profile .profile-general-info").addClass("hide");
                        $(".profile .profile-security").removeClass("hide");
                        $(".profile .profile-plan").addClass("hide");
                });
                $("body").on("click", ".profile .nav-plan", function() {
                        $(this).addClass("active");
                        $(".profile .nav-general").removeClass("active");
                        $(".profile .nav-security").removeClass("active");

                        $(".profile .profile-general-info").addClass("hide");
                        $(".profile .profile-security").addClass("hide");
                        $(".profile .profile-plan").removeClass("hide");
                });

                // Calendar Hover

                // $("#caleandar").on('mouseover', '.cld-number.eventday', function(e) {
                //   if (e.target !== this)
                //     return;

                //   $(".cld-number.eventday").not(this).find(".cld-title").css("display", "none");
                //   $(this).find(".cld-title").toggle();
                // });

                $("body").on("click", ".ivr-card-item .btn-actions", function(e) {
                        $(".ivr-card-item .btn-actions").not(this).siblings(".actions").css("display", "none");
                        $(this).siblings(".actions").toggle();
                });

                $('.credit.pr-chart-ctrl').each(function() {
                        var credit_value = $(this).find(".pr-chart").attr("data-value");
                        var credit_overall = $(this).find(".pr-chart").attr("data-overall");
                        var credit_percent = (parseFloat(credit_value) / parseFloat(credit_overall)) * 100;
                        $(this).find(".pr-chart").attr("data-percent", credit_percent);
                });

                $(document).mouseup(function(e) {
                        var container = $("#notif-wrap1");
                        var calendar_event = $("#caleandar .cld-number.eventday");

                        // if the target of the click isn't the container nor a descendant of the container
                        if (!container.is(e.target) && container.has(e.target).length === 0) {
                                // container.hide();
                                container.find("#notification-popup").hide();
                        }
                });
                $(document).mouseup(function(e) {
                        var calendar_event = $("#caleandar .cld-number.eventday");

                        // if the target of the click isn't the container nor a descendant of the container
                        if (!calendar_event.is(e.target) && calendar_event.has(e.target).length === 0) {
                                // container.hide();
                                calendar_event.find(".cld-title").hide();
                        }
                });
                $(document).mouseup(function(e) {
                        var ivr_filter = $(".ivr-filter");

                        // if the target of the click isn't the container nor a descendant of the container
                        if (!ivr_filter.is(e.target) && ivr_filter.has(e.target).length === 0) {
                                // container.hide();
                                ivr_filter.find(".filter-list").hide();
                        }
                });
                // $(document).mouseup(function(e)
                // {
                //     var ivr_card_action = $(".ivr-list .ivr-card-item .upper .btn-actions");

                //     // if the target of the click isn't the container nor a descendant of the container
                //     if (!ivr_card_action.is(e.target) && ivr_card_action.has(e.target).length === 0)
                //     {
                //         // container.hide();
                //         ivr_card_action.next(".actions").hide();
                //     }
                // });

                $(".numbers-page .caller-id-nav").click(function(e) {
                        $(this).addClass("active");
                        $(".numbers-page .sms-nav").removeClass("active");
                        $(".numbers-page").find(".sms-tab").css("display", "none");
                        $(".numbers-page").find(".caller-id-tab").css("display", "block");
                });
                $(".numbers-page .sms-nav").click(function(e) {
                        $(this).addClass("active");
                        $(".numbers-page .caller-id-nav").removeClass("active");
                        $(".numbers-page").find(".caller-id-tab").css("display", "none");
                        $(".numbers-page").find(".sms-tab").css("display", "block");
                });
                $(" .signup .form-item #mobile-num").keypress(function(key) {
                        if (key.which < 48 || key.which > 57) {
                                key.preventDefault();
                        }
                });
                // $("#modal-campaign-create .left  #campaign-time").addClass("x");
                // $("#modal-campaign-create .left #campaign-schedule").change(function(){
                //  if(!$(this).val()){
                //      $("#modal-campaign-create .left  #campaign-time").val("");
                //       $("#modal-campaign-create .left  #campaign-time").addClass("x");
                //  }
                //  else $("#modal-campaign-create .left  #campaign-time").removeClass("x");
                // });
                // $('#statistics-donut').children('div').each(function () {
                //     alert($(this).attr('data-value')); // "this" is the current element in the loop
                // });

                // var val_answered = parseInt($('#donut-answered').attr('data-value'));
                // var val_missed = parseInt($('#donut-missed').attr('data-value'));
                // var val_rejected = parseInt($('#donut-rejected').attr('data-value'));
                // var val_unreachable = parseInt($('#donut-unreachable').attr('data-value'));
                //
                // $("#label-answered").html(val_answered);
                // $("#label-missed").html(val_missed);
                // $("#label-rejected").html(val_rejected);
                // $("#label-unreachable").html(val_unreachable);
                //
                // var stats_total = val_answered + val_missed + val_rejected + val_unreachable;
                // $(".chart-wrap .total h2").html(stats_total);
                //
                // if (stats_total <= 0) {
                //     $("canvas#statistics-donut").addClass("zero-stats");
                // } else {
                //     $("canvas#statistics-donut").removeClass("zero-stats");
                // }
                //
                // applyCustom2()

        });

        // $(function(){
        //     var dtToday = new Date();

        //     var month = dtToday.getMonth() + 1;
        //     var day = dtToday.getDate();
        //     var year = dtToday.getFullYear();
        //     if(month < 10)
        //         month = '0' + month.toString();
        //     if(day < 10)
        //         day = '0' + day.toString();

        //     var maxDate = year + '-' + month + '-' + day;
        //     $('#campaign-schedule').attr('min', maxDate);
        // });

        //Validations Functions

        var emailInput = $("#form-signup").find("#email");
        var mobileInput = $("#form-signup").find("#mobile-num");
        var passwordInput = $("#form-signup").find("#password");
        var confirmPasswordInput = $("#form-signup").find("#confirm-password");

        // TODO(aes): no longer needed?
        // function validateEmail() {
        //         // alert(2);
        //         var emailStr = emailInput.val();
        //         var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

        //         if (emailInput.val() != '') {
        //                 if (emailReg.test(emailStr)) {
        //                         emailInput.siblings(".error-msg").css("opacity", "0");
        //                         return true;
        //                 } else {
        //                         emailInput.siblings(".error-msg").css("opacity", "1");
        //                         emailInput.siblings(".error-msg.blank").css("opacity", "0");
        //                         return false;
        //                 }
        //         } else {
        //                 emailInput.siblings(".error-msg").css("opacity", "0");
        //                 emailInput.siblings(".error-msg.blank").css("opacity", "1");
        //                 return false;
        //         }
        // }

        // TODO(aes): no longer needed?
        // function validateMobileNum() {
        //         // alert(1);
        //         if (mobileInput.val() != '') {
        //                 if (!(mobileInput.val().length < 11)) {
        //                         mobileInput.siblings(".error-msg").css("opacity", "0");
        //                         return true;
        //                 } else {
        //                         mobileInput.siblings(".error-msg").css("opacity", "1");
        //                         mobileInput.siblings(".error-msg.blank").css("opacity", "0");
        //                         return false;
        //                 }
        //         } else {
        //                 mobileInput.siblings(".error-msg").css("opacity", "0");
        //                 mobileInput.siblings(".error-msg.blank").css("opacity", "1");
        //                 return false;
        //         }
        // }

        // TODO(aes): no longer needed?
        // function validatePassword() {
        //         if (passwordInput.val() != '') {
        //                 if (!(passwordInput.val().length < 6)) {
        //                         passwordInput.siblings(".error-msg").css("opacity", "0");
        //                         return true;
        //                 } else {
        //                         passwordInput.siblings(".error-msg").css("opacity", "1");
        //                         passwordInput.siblings(".error-msg.blank").css("opacity", "0");
        //                         return false;
        //                 }
        //         } else {
        //                 passwordInput.siblings(".error-msg").css("opacity", "0");
        //                 passwordInput.siblings(".error-msg.blank").css("opacity", "1");
        //                 return false;
        //         }
        // }

        window.validateConfirmPassword = function validateConfirmPassword() {

                if (confirmPasswordInput.val() != '') {
                        if (confirmPasswordInput.val() == passwordInput.val()) {
                                confirmPasswordInput.siblings(".error-msg").css("opacity", "0");
                                return true;
                        } else {
                                confirmPasswordInput.siblings(".error-msg").css("opacity", "1");
                                // confirmPasswordInput.siblings(".error-msg.blank").css("opacity","0");
                                return false;
                        }
                } else {
                        confirmPasswordInput.siblings(".error-msg").css("opacity", "0");
                        // confirmPasswordInput.siblings(".error-msg.blank").css("opacity","1");
                        return false;
                }
        }

        window.validate = function validate(evt) {
                var theEvent = evt || window.event;
                var key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);
                var regex = /[0-9]|\./;
                if (!regex.test(key)) {
                        theEvent.returnValue = false;
                        if (theEvent.preventDefault) theEvent.preventDefault();
                }
        }

        //Campaign Calendar ---------------------------------------------- start
        // !!! COMMENT OUT THIS PART for caleandar and it is already placed in Vue
        // Campaign item
        // var item_date = "12:00 PM - 18 March 2018";
        // var item_title = "Billing Campaign";
        // var item_status = "Failed";
        // var item_ivr = "Billing Campaign";
        // var item_variables = "Last name, middle name, first name, address, age";
        // var item_callerid = "09123456789";
        // var item_smsmasking = "IVES";
        // var item_voice = "Male";
        // var hover_item = '<div class="hover-calendar-item-details-wrap hide">\
        //                     <div class="hover-calendar-item-details">\
        //                       <div class="hover-date">\
        //                         <img src="assets/client/images/ic_chevright.svg" class="back-btn">' + item_date + '\
        //                       </div><br>\
        //                       <div class="hover-title">\
        //                         <h1>' + item_title + '</h1>\
        //                         <div class="status failed">' + item_status + '</div>\
        //                       </div>\
        //                       <div class="hover-details"><br>\
        //                           <div><div id="ic-callerid" class="ic"></div><strong>Caller ID: </strong>' + item_callerid + '</div><br>\
        //                           <div><div id="ic-smsmasking" class="ic"></div><strong>SMS Masking: </strong>' + item_smsmasking + '</div><br>\
        //                           <div><div id="ic-voice" class="ic"></div><strong>Voice: </strong>' + item_voice + '</div><br>\
        //                           <div><div id="ic-variables" class="ic"></div><strong>Variables: </strong><div class="item-variables">' + item_variables + '</div></div><br>\
        //                       </div>\
        //                     </div>\
        //                   </div>';
        //
        // // Campaign Date
        // var campaign_date = '18 March 2010';
        // var campaign_status = 'failed';
        // var campaign_title = 'Billing Campaign';
        // var campaign_time = '12:00 PM'
        // var hover_calendar = '<div class="hover-calendar-wrap"><div class="hover-calendar">\
        //                     <div class="hover-calendar-date"><img src="assets/client/images/ic_calendar_white.svg">' + campaign_date + '</div>\
        //                     <div class="hover-calendar-item">\
        //                       <div class="left"><div class="' + campaign_status + '">' + campaign_status + '</div></div>\
        //                       <div class="center">\
        //                         ' + campaign_title + '\
        //                         <br>\
        //                         <span class="time">' + campaign_time + '</span>\
        //                       </div>\
        //                       <div class="right"><span class="open-campaign-details"><img src="assets/client/images/ic_chevleft.svg"></span></div>\
        //                     </div>\
        //                   </div>\
        //                   ' + hover_item + '\
        //                 </div>';
        // //Campaign Calendar ---------------------------------------------- end
        //
        // var events = [{
        //         'Date': new Date(2018, 2, 23),
        //         'Title': hover_calendar
        //     },
        //     {
        //         'Date': new Date(2018, 3, 7),
        //         'Title': hover_calendar
        //     },
        //     {
        //         'Date': new Date(2018, 3, 23),
        //         'Title': hover_calendar
        //     },
        //     {
        //         'Date': new Date(2018, 4, 5),
        //         'Title': hover_calendar
        //     },
        //     {
        //         'Date': new Date(2018, 4, 7),
        //         'Title': hover_calendar
        //     },
        // ];
        // var settings = {};
        // var element = document.getElementById('caleandar');
        // caleandar(element, events, settings);
        //
        // function fileValidation(){
        //     var fileInput = document.getElementById('create-campaign-csv');
        //     var filePath = fileInput.value;
        //     // var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        //     var allowedExtensions = /(\.csv)$/i;
        //     if(!allowedExtensions.exec(filePath)){
        //         // alert('Please upload file having extensions .jpeg/.jpg/.png/.gif only.');
        //         fileInput.value = '';
        //         return false;
        //     }else{
        //         //Image preview
        //         if (fileInput.files && fileInput.files[0]) {
        //             var reader = new FileReader();
        //             reader.onload = function(e) {
        //                 document.getElementById('imagePreview').innerHTML = '<img src="'+e.target.result+'"/>';
        //             };
        //             reader.readAsDataURL(fileInput.files[0]);
        //         }
        //     }
        // }



        // // Custom 2 - Albert
        // var currentDate = new Date();

        // function applyCustom2() {
        //   $(document).find('.cld-nav.cld-rwd').off('click');
        //   $(document).find('.cld-nav.cld-rwd').on('click', function() {

        //   });
        //   $(document).find('.cld-nav.cld-fwd').off('click');
        //   $(document).find('.cld-nav.cld-fwd').on('click', function() {

        //   });

        // }
        // $('.cld-nav').show();

        // TODO(aes): no longer needed?
        // function loadCalendarData(cdate) {
        //         const tmp = {
        //                 year: cdate.getFullYear(),
        //                 month: cdate.getMonth() + 1,
        //         }
        //         console.log(tmp);
        //         // Rebind event
        // }
}
